import { SliderIndicators } from "@components/SliderIndicators"
import { CleanIconButton } from "@components/ui/button/CleanIconButton"
import { Container } from "@components/ui/Container"
import IconChevronLeft from "@components/ui/icons/IconChevronLeft"
import IconChevronRight from "@components/ui/icons/IconChevronRight"
import { UseSliderPayload } from "@components/ui/slider"
import { parse } from "@config/theme"
import { FC } from "react"

type LandingPageSuccessStoriesSliderControlsProps = Pick<
  UseSliderPayload,
  "next" | "prev" | "canMoveLeft" | "canMoveRight" | "index"
> & {
  slideLength: number
}

export const LandingPageSuccessStoriesSliderControls: FC<
  LandingPageSuccessStoriesSliderControlsProps
> = ({ next, prev, canMoveLeft, canMoveRight, index, slideLength }) => {
  return (
    <Container size="medium" as="div">
      <div className={desktopButtons}>
        <CleanIconButton
          disabled={!canMoveLeft}
          onClick={prev}
          label="previous"
        >
          <IconChevronLeft />
        </CleanIconButton>
        <CleanIconButton disabled={!canMoveRight} onClick={next} label="next">
          <IconChevronRight />
        </CleanIconButton>
      </div>
      <div className={mobileIndicators}>
        <SliderIndicators amount={slideLength} activeIndex={index} />
      </div>
    </Container>
  )
}

const desktopButtons = parse({
  display: { _: "none", large: "flex" },
  alignItems: "center",
  justifyContent: "flex-end",
})

const mobileIndicators = parse({
  display: { _: "flex", large: "none" },
  alignItems: "center",
  justifyContent: "center",
})
