import { Container } from "@components/ui/Container";
import { HeadingLink } from "@components/ui/heading-link";
import { Heading } from "@components/ui/typograhpy/Heading";
import { parse, theme } from "@config/theme";
import { css } from "@linaria/core";
import Link from "next/link";
import { FC } from "react";
type LandingPageHeroTextAndButtonsProps = {
  title: string;
};
export const LandingPageHeroTextAndButtons: FC<LandingPageHeroTextAndButtonsProps> = ({
  title
}) => {
  return <Container className={text} as="div">
      <Heading variant="h1" as="h1" mb={{
      _: "24",
      medium: "32"
    }}>
        {title}
      </Heading>
      <div className={buttons}>
        <Link href="/signup/form" passHref>
          <HeadingLink as="link" variant="internal" color="gradient" size="h3">
            Try for free
          </HeadingLink>
        </Link>
        <HeadingLink as="link" variant="external" color="grey" size="h3" href="https://docs.viesus.cloud" target="blank">
          Read the docs
        </HeadingLink>
      </div>
    </Container>;
};
const text = parse({
  textAlign: "center"
}, "t7wtum0");
const buttons = parse({
  display: "flex",
  alignItems: "center",
  justifyContent: "center"
}, "b1vuqx6x");

require("./TextAndButtons.linaria.module.css!=!../../../node_modules/@linaria/webpack5-loader/lib/outputCssLoader.js?cacheProvider=!./TextAndButtons.tsx");