import { parse, theme } from "@config/theme";
import { css } from "@linaria/core";
import { rgba } from "polished";
export const parent = parse({
  display: {
    _: "flex",
    large: "grid"
  },
  position: "relative",
  alignItems: "center",
  pt: {
    _: "0",
    large: "56"
  },
  pb: {
    _: "48",
    large: "56"
  }
}, "p1bwosle");
export const text = parse({
  display: "flex",
  alignItems: {
    _: "center",
    large: "flex-start"
  },
  justifyContent: {
    _: "center",
    large: "flex-start"
  },
  textAlign: {
    _: "center",
    large: "left"
  }
}, "t1p0wrvf");
export const images = parse({
  position: "relative"
});
export const main = parse({
  display: "flex"
}, "m1vslfao");
export const detail = parse({
  position: "absolute"
}, "d13qhb7z");
export const mobileChain = parse({
  display: {
    _: "flex",
    large: "none"
  },
  alignItems: "flex-end",
  position: "relative"
}, "m1ge6on7");
export const desktopChain = parse({
  display: {
    _: "none",
    large: "flex"
  },
  position: "absolute",
  alignItems: "center",
  height: "100%"
}, "d1gvurwu");
export const dot = parse({
  bg: "shade800"
}, "d1trfxn3");

require("./Item.styles.linaria.module.css!=!../../../node_modules/@linaria/webpack5-loader/lib/outputCssLoader.js?cacheProvider=!./Item.styles.ts");