import React, { FC } from "react";
import { parse, theme } from "@config/theme";
import { css } from "@linaria/core";
export const LandingPageEnhancementProcessDot: FC = () => {
  return <span className={dot} />;
};
export const dot = parse({
  bg: "shade800"
}, "dxec2pp");

require("./Dot.linaria.module.css!=!../../../node_modules/@linaria/webpack5-loader/lib/outputCssLoader.js?cacheProvider=!./Dot.tsx");