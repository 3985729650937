import { parse, theme } from "@config/theme";
import { css } from "@linaria/core";
export const item = parse({
  display: "flex"
}, "icg8cbz");
export const text = parse({
  mt: "8"
});
export const customer = parse({
  display: "grid"
}, "c1jfizhy");
export const avatar = parse({
  display: "flex",
  mb: "32"
}, "airlcpr");
export const parent = parse({
  display: "grid"
}, "p1r5cwdd");
export const imageParent = parse({
  display: "flex",
  mb: "32"
}, "i18y8h1m");

require("./Testimonials.styles.linaria.module.css!=!../../node_modules/@linaria/webpack5-loader/lib/outputCssLoader.js?cacheProvider=!./Testimonials.styles.ts");