import { css, cx } from "@linaria/core";
import React, { FC, HTMLAttributes } from "react";
export type IconProps = HTMLAttributes<SVGElement> & {
  size?: number;
  fill?: string;
};
export const Icon: FC<IconProps> = ({
  size = 24,
  className,
  ...rest
}) => {
  return <svg vectorEffect="non-scaling-stroke" viewBox="0 0 32 32" className={cx(icon, className)} fill="none" width={size} height={size} {...rest} />;
};
const icon = "iiyedy";

require("./Icon.linaria.module.css!=!../../../node_modules/@linaria/webpack5-loader/lib/outputCssLoader.js?cacheProvider=!./Icon.tsx");