import { parse, theme } from "@config/theme";
import { css } from "@linaria/core";
export const parent = parse({
  display: "flex",
  bg: "borderDisabled"
}, "p1uq8i1w");
export const imageMobile = parse({
  display: {
    _: "flex",
    large: "none"
  },
  pl: {
    _: "32",
    large: "40"
  }
});
export const imageDesktop = parse({
  display: {
    _: "none",
    large: "flex"
  },
  pl: {
    _: "32",
    large: "40"
  }
});
export const text = parse({
  py: {
    _: "40"
  },
  px: {
    _: "32",
    large: "40"
  },
  mb: {
    _: "32",
    large: "40"
  }
});
export const label = parse({
  py: "4",
  px: "8",
  bg: "border",
  display: "inline-block",
  mt: "16"
}, "l1dps30a");

require("./SmallFeature.styles.linaria.module.css!=!../../../node_modules/@linaria/webpack5-loader/lib/outputCssLoader.js?cacheProvider=!./SmallFeature.styles.ts");