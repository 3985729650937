import { HeadingLink } from "@components/ui/heading-link"
import Link from "next/link"
import React, { FC } from "react"
import { LandingPageEnhancementProcessDot } from "./Dot"
import * as styles from "./Buttons.styles"

export const LandingPageEnhancementProcessButtons: FC = () => {
  return (
    <div className={styles.buttons}>
      <div className={styles.chain}>
        <LandingPageEnhancementProcessDot />
      </div>
      <Link href="/signup" passHref>
        <HeadingLink variant="internal" color="gradient" size="h3">
          Get started with the API
        </HeadingLink>
      </Link>
      <HeadingLink
        href="https://viesus.com"
        target="_blank"
        variant="external"
        color="grey"
        mb="8"
        size="h3"
      >
        Our standalone app or SDK
      </HeadingLink>
    </div>
  )
}
