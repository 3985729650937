import { ContentPageLayout } from "@components/content-page/layout"
import { LandingPageEnhancementProcess } from "@components/_landingpage/enhancement-process"
import { LandingPageFeatures } from "@components/_landingpage/features"
import { LandingPageHero } from "@components/_landingpage/hero"
import { LandingPageSuccessStories } from "@components/_landingpage/success-stories"
import { LandingPageTestimonials } from "@components/_landingpage/Testimonials"
import { LandingPageUsps } from "@components/_landingpage/Usps"
import { getDrafts, getSanityClient } from "@lib/sanity"
import { getFooterFromSanity } from "@lib/sanity/helpers/get-footer-from-sanity"
import { GetStaticProps, NextPage } from "next"
import { FooterSingleton, HomepageSingleton } from "__generated__/sanity"

type HomepageProps = {
  content: HomepageSingleton
  footer: FooterSingleton
}

const Homepage: NextPage<HomepageProps> = ({ content, footer }) => {
  return (
    <ContentPageLayout footer={footer} seo={content.fields.seo}>
      <LandingPageHero {...content.fields.hero} />
      <LandingPageFeatures {...content.fields.features} />
      <LandingPageUsps {...content.fields.usps} />
      <LandingPageEnhancementProcess {...content.fields.process} />
      <LandingPageSuccessStories {...content.fields.successStories} />
      <LandingPageTestimonials {...content.fields.testimonials} />
    </ContentPageLayout>
  )
}

export default Homepage

export const getStaticProps: GetStaticProps = async ({ preview }) => {
  const sanity = getSanityClient(preview)
  const footer = await getFooterFromSanity(preview)

  const homepageContent = await sanity.fetch(`*[_type == "homepage-singleton"] {
    ...
  }`)

  return {
    props: {
      content: getDrafts(homepageContent)[0],
      footer,
    },
  }
}
