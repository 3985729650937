import { Container } from "@components/ui/Container"
import { Heading } from "@components/ui/typograhpy/Heading"
import { Text } from "@components/ui/typograhpy/Text"
import { getSanityImageDimensions } from "@lib/get-sanity-image-dimensions"
import { urlFor } from "@lib/sanity"
import Image from "next/image"
import { FC } from "react"
import { HomepageSingleton } from "__generated__/sanity"
import * as styles from "./Testimonials.styles"

type LandingPageTestimonialsProps = HomepageSingleton["fields"]["testimonials"]

export const LandingPageTestimonials: FC<LandingPageTestimonialsProps> = ({
  testimonialItems,
}) => {
  return (
    <Container size="medium" className={styles.parent}>
      {testimonialItems.map((testimonial) => {
        const { width: logoWidth, height: logoHeight } =
          getSanityImageDimensions(testimonial.testimonialItemLogo.asset._ref)
        const { width: avatarWidth, height: avatarHeight } =
          getSanityImageDimensions(testimonial.testimonialItemAvatar.asset._ref)

        return (
          <div className={styles.item} key={testimonial._key}>
            <div className={styles.imageParent}>
              <Image
                src={urlFor(testimonial.testimonialItemLogo)
                  .auto("format")
                  .width(300)
                  .toString()}
                alt={testimonial.testimonialItemCustomerName}
                height={logoHeight}
                width={logoWidth}
              />
            </div>
            <Text variant="special" mb="32">
              {testimonial.testimonialItemContent}
            </Text>
            <div className={styles.customer}>
              <div className={styles.avatar}>
                <Image
                  src={urlFor(testimonial.testimonialItemAvatar)
                    .auto("format")
                    .width(120)
                    .toString()}
                  alt={testimonial.testimonialItemCustomerName}
                  height={avatarHeight}
                  width={avatarWidth}
                />
              </div>
              <div className={styles.text}>
                <Heading variant="h6" as="h5" mb="0">
                  {testimonial.testimonialItemCustomerName}
                </Heading>
                <Text variant="large" color="shade400">
                  {testimonial.testimonialItemCustomerJobTitle}
                </Text>
              </div>
            </div>
          </div>
        )
      })}
    </Container>
  )
}
