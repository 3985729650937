import { Heading } from "@components/ui/typograhpy/Heading"
import { Text } from "@components/ui/typograhpy/Text"
import * as styles from "./SmallFeature.styles"
import { getSanityImageDimensions } from "@lib/get-sanity-image-dimensions"
import { urlFor } from "@lib/sanity"

import Image from "next/image"
import React, { FC } from "react"
import { HomepageSingleton } from "__generated__/sanity"
import { HeadingLink } from "@components/ui/heading-link"
import Link from "next/link"

type BlockType =
  | HomepageSingleton["fields"]["features"]["featureBlocks"]["firstSmallFeatureBlock"]
  | HomepageSingleton["fields"]["features"]["featureBlocks"]["secondSmallFeatureBlock"]

type LandingPageFeaturesSmallFeatureProps = BlockType

export const LandingPageFeaturesSmallFeature: FC<
  LandingPageFeaturesSmallFeatureProps
> = ({
  title,
  description,
  mobileImage,
  desktopImage,
  showComingSoon,
  link,
}) => {
  const { width: mobileWidth, height: mobileHeight } = getSanityImageDimensions(
    mobileImage.asset._ref
  )
  const { width: desktopWidth, height: desktopHeight } =
    getSanityImageDimensions(desktopImage.asset._ref)

  return (
    <div className={styles.parent}>
      <div className={styles.text}>
        <Heading variant="h4" as="h3" mb="16">
          {title}
        </Heading>
        <Text variant="large" mb="0">
          {description}
        </Text>
        {showComingSoon && <span className={styles.label}>Coming soon</span>}
        {link && link.href && link.text && link.type && (
          <>
            {link.type === "external" ? (
              <HeadingLink
                mt="16"
                as="link"
                href={link.href}
                target="_blank"
                variant="external"
                size="h6"
                color="gradient"
              >
                {link.text}
              </HeadingLink>
            ) : (
              <Link href={link.href} passHref>
                <HeadingLink
                  mt="16"
                  as="link"
                  variant="internal"
                  size="h6"
                  color="gradient"
                >
                  {link.text}
                </HeadingLink>
              </Link>
            )}
          </>
        )}
      </div>
      <div>
        <div className={styles.imageMobile}>
          <Image
            src={urlFor(mobileImage).auto("format").width(800).toString()}
            alt="Hero main image"
            height={mobileHeight}
            width={mobileWidth}
          />
        </div>
        <div className={styles.imageDesktop}>
          <Image
            src={urlFor(desktopImage).auto("format").width(800).toString()}
            alt="Hero main image"
            height={desktopHeight}
            width={desktopWidth}
          />
        </div>
      </div>
    </div>
  )
}
