import { Container } from "@components/ui/Container"
import { FC } from "react"
import { HomepageSingleton } from "__generated__/sanity"
import { ContentPageSectionTitle } from "../../content-page/SectionTitle"
import { LandingPageEnhancementProcessButtons } from "./Buttons"
import { LandingPageEnhancementProcessItem } from "./Item"

type LandingPageEnhancementProcessProps = HomepageSingleton["fields"]["process"]

export const LandingPageEnhancementProcess: FC<
  LandingPageEnhancementProcessProps
> = ({ title, description, steps }) => {
  return (
    <Container size="medium">
      <ContentPageSectionTitle title={title} description={description} />
      {steps.map((step, key) => (
        <LandingPageEnhancementProcessItem
          {...step}
          isFirst={key === 0}
          key={step._key}
        />
      ))}
      <LandingPageEnhancementProcessButtons />
    </Container>
  )
}
