import { Container } from "@components/ui/Container"
import { FC } from "react"
import { HomepageSingleton } from "__generated__/sanity"
import { ContentPageSectionTitle } from "../../content-page/SectionTitle"
import { LandingPageFeaturesBigFeature } from "./BigFeature"
import { LandingPageFeaturesSmallFeature } from "./SmallFeature"
import * as styles from "./styles"

type LandingPageFeaturesProps = HomepageSingleton["fields"]["features"]

export const LandingPageFeatures: FC<LandingPageFeaturesProps> = ({
  title,
  description,
  featureBlocks,
}) => {
  return (
    <Container size="medium">
      <ContentPageSectionTitle title={title} description={description} />
      <div className={styles.grid}>
        <LandingPageFeaturesBigFeature
          variant="text-image"
          {...featureBlocks.firstBigFeatureBlock}
        />
        <LandingPageFeaturesSmallFeature
          {...featureBlocks.firstSmallFeatureBlock}
        />
        <LandingPageFeaturesSmallFeature
          {...featureBlocks.secondSmallFeatureBlock}
        />
        <LandingPageFeaturesBigFeature
          variant="image-text"
          {...featureBlocks.secondBigFeatureBlock}
        />
      </div>
    </Container>
  )
}
