import { parse, theme } from "@config/theme";
import { css } from "@linaria/core";
import { rgba } from "polished";
export const container = parse({
  position: "relative"
}, "c1ls4h5n");
export const mainImageContainer = parse({
  px: {
    _: "16",
    medium: "0"
  }
}, "m1vxknef");
export const scrollContainer = parse({}, "s1ujbvam");
export const codeImageContainer = parse({
  position: "absolute"
}, "cu6srqt");

require("./Images.styles.linaria.module.css!=!../../../node_modules/@linaria/webpack5-loader/lib/outputCssLoader.js?cacheProvider=!./Images.styles.ts");