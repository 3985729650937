import { HeadingLink } from "@components/ui/heading-link"
import { Heading } from "@components/ui/typograhpy/Heading"
import { Text } from "@components/ui/typograhpy/Text"
import { urlFor } from "@lib/sanity"
import Image from "next/image"
import Link from "next/link"
import { FC } from "react"
import { HomepageSingleton } from "__generated__/sanity"
import * as styles from "./SliderItem.styles"

export type LandingPageSuccessStoriesItemProps =
  HomepageSingleton["fields"]["successStories"]["slides"][0] & {
    position: "first" | "middle" | "last"
  }

export const LandingPageSuccessStoriesItem: FC<
  LandingPageSuccessStoriesItemProps
> = ({ slideDescription, slideImage, slideTitle, link, position }) => {
  return (
    <div className={styles.parent} data-position={position}>
      <div className={styles.item}>
        <div className={styles.content}>
          <Heading variant="h4" as="h4" mb="16">
            {slideTitle}
          </Heading>
          <Text variant="large" mb="0">
            {slideDescription}
          </Text>
          {link && link.href && link.text && link.type && (
            <>
              {link.type === "external" ? (
                <HeadingLink
                  mt="16"
                  as="link"
                  href={link.href}
                  target="_blank"
                  variant="external"
                  size="h6"
                  color="gradient"
                >
                  {link.text}
                </HeadingLink>
              ) : (
                <Link href={link.href} passHref>
                  <HeadingLink
                    mt="16"
                    as="link"
                    variant="internal"
                    size="h6"
                    color="gradient"
                  >
                    {link.text}
                  </HeadingLink>
                </Link>
              )}
            </>
          )}
        </div>
        <div className={styles.image}>
          <Image
            src={urlFor(slideImage).auto("format").width(1500).toString()}
            alt="Hero main image"
            layout="fill"
            objectFit="cover"
          />
        </div>
      </div>
    </div>
  )
}
