const pattern = /^image-([a-f\d]+)-(\d+x\d+)-(\w+)$/

export const getSanityImageDimensions = (ref: string) => {
  // @ts-ignore
  const [, , dimensions] = pattern.exec(ref)
  const [width, height] = dimensions.split("x").map((v) => parseInt(v, 10))

  return {
    width,
    height,
  }
}
