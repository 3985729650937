import { Container } from "@components/ui/Container";
import { parseAll, theme } from "@config/theme";
import { css } from "@linaria/core";
import { FC } from "react";
import { HomepageSingleton } from "__generated__/sanity";
import { LandingPageHeroImages } from "./Images";
import { LandingPageHeroStats } from "./Stats";
import { LandingPageHeroTextAndButtons } from "./TextAndButtons";
type LandingPageHeroProps = HomepageSingleton["fields"]["hero"];
export const LandingPageHero: FC<LandingPageHeroProps> = ({
  title,
  mainImage,
  codeImage
}) => {
  return <section className={parent}>
      <LandingPageHeroTextAndButtons title={title} />
      <Container size="medium" className={parseAll({
      position: "relative"
    })} as="div">
        <LandingPageHeroImages mainImage={mainImage} codeImage={codeImage} />
        <LandingPageHeroStats />
      </Container>
    </section>;
};
const parent = "pn3b7k8";

require("./index.linaria.module.css!=!../../../node_modules/@linaria/webpack5-loader/lib/outputCssLoader.js?cacheProvider=!./index.tsx");