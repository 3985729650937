import { parse, parseAll, theme, Theme } from "@config/theme";
import { css, cx } from "@linaria/core";
import { rem } from "polished";
import React, { AnchorHTMLAttributes, FC, HTMLAttributes } from "react";
import { ThemeSystemProps } from "theme-system";
type SpanVariant = Omit<HTMLAttributes<HTMLSpanElement>, "color"> & {
  as?: "span";
};
type DivVariant = Omit<HTMLAttributes<HTMLDivElement>, "color"> & {
  as?: "div";
};
type LinkVariant = Omit<AnchorHTMLAttributes<HTMLAnchorElement>, "color"> & {
  as?: "a";
};
type ElementType = LinkVariant | DivVariant | SpanVariant;
type SpanProps = Pick<ThemeSystemProps<Theme>, "mb" | "mr" | "mt" | "ml" | "color" | "display"> & ElementType & {
  variant: "button-label-big" | "button-label-small" | "input-label" | "input-label-active";
};
export const Span: FC<SpanProps> = ({
  color,
  as = "span",
  variant,
  className,
  ...rest
}) => {
  const Comp = as;
  return (
    // @ts-ignore
    <Comp data-variant={variant} className={cx(className, parseAll({
      color,
      ...rest
    }), span)} {...rest} />
  );
};
const span = parse({}, "s1at3pxw");

require("./Span.linaria.module.css!=!../../../node_modules/@linaria/webpack5-loader/lib/outputCssLoader.js?cacheProvider=!./Span.tsx");